import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SEO } from 'components';
import { BlogContainer } from '../components/blog';
import { BlogLayout } from '../layouts';

const Wrapper = styled.div`
  background: #F0F3F4;
`;

const Blog = ({ data, location }) => {
  const { posts, categories } = data.gcms; // All of the blog posts & categories
  const allPosts = posts.filter(post => !post.featured && post.category.slug !== 'reinvent');
  const featuredPost = posts.filter(post => post.featured)[0]; // Only one post should be featured at a time

  return (
    <Wrapper>
      <BlogLayout location={location}>
        <Helmet title={'Stacks on Stacks: The Stackery Blog'} />
        <SEO
          description='Keep up to date on the latest from Stackery and serverless development.'
        />
        <BlogContainer
          featuredPost={featuredPost}
          posts={allPosts}
          categories={categories.filter(category => category.slug !== 'reinvent')}
        />
      </BlogLayout>
    </Wrapper>
  );
};

export default Blog;

Blog.propTypes = {
  data: PropTypes.shape({
    gcms: PropTypes.shape({
      categories: PropTypes.array,
      posts: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            excerpt: PropTypes.string,
            coverImage: PropTypes.object.isRequired,
            path: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            subtitle: PropTypes.string,
            date: PropTypes.string.isRequired,
            author: PropTypes.object.isRequired,
            readtime: PropTypes.number,
            category: PropTypes.object.isRequired,
            tags: PropTypes.array
          })
        }).isRequired
      )
    })
  }),
  location: PropTypes.object
};

export const query = graphql`
  query {
    gcms {
      categories(orderBy: name_ASC) {
        name
        slug
      }
      posts(orderBy: date_DESC) {
        id
        title
        subtitle
        coverImage {
          url
          node {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        author {
          name
          picture {
            url(transformation: {image: {resize: {}}, document: {output: {format: jpg}}})
          }
        }
        excerpt
        slug
        date
        category {
          name
          slug
        }
        tags {
          name
          slug
        }
        featured
        popularPost
        readtime
      }
    }
  }
`;
